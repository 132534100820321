body {
  background: linear-gradient(to left top, #9c559c, #6eb6c3);
  background-attachment: fixed; /*edit*/
}

.topline {
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
}

.header {
  color: white;
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.box {
  border: 1px solid black;
  background-color: white;
  border-radius: 5px;
  width: 60%;
  margin: 0 auto;
  margin-bottom: 50px;
}

.subheader {
  text-align: center;
}

.links {
  text-align: center;
  margin-bottom: 0px;
}

.text {
  display: inline-block;
  padding: 0;
}

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

.verify {
  cursor: pointer;
  width: 150px;
}

.linkBox {
  cursor: pointer;
  width: 100px;
  margin: 0 2px;
}

.links {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin: 0 auto;
  padding: 10px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
.spinner-container {
  display: flex;
  justify-content: center;
  padding-top: 300px;
}

.error-container {
  border: 1px solid black;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  margin-top: 300px;
}

.riskBox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.riskBoxInner {
  width: 50%;
  border: 1px solid #96151e;
  border-radius: 4px;
  background: #dbd0d1;
  text-align: center;
  padding: 5px;
  display: flex;
  align-items: center;
}

.riskBoxText {
  font-size: 15px;
  width: 100%;
}

@media (max-width: 520px) {
  .riskBox {
    padding-top: 75px;
  }
  .riskBoxInner {
    width: 75%;
  }
  .riskBoxText {
    font-size: 14px;
  }
  .box {
    width: 90%;
  }
  .links {
    flex-wrap: wrap;
    width: 95%;
  }
  .header {
    padding-top: 0px;
  }
}
